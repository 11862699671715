import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "./../plugins/i18n";
import store from "../store";

import ForgotPassword from "../views/login/ForgotPassword.vue";
// import decoder    from 'jwt-decode'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: `/${i18n.locale}`,
    meta: { requiresAuth: true },
  },
  {
    path: "/:lang",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "login",
        component: () => import("../views/login/LoginPage"),
        children: [
          {
            path: "",
            name: "Login",
            component: () => import("../components/login/Login"),
          },
        ],
      },
      //confirmation code route
      {
        path: "confirmation-code",
        component: () => import("../views/login/ConfirmCode"),
        children: [
          {
            path: "",
            name: "ConfirmationCode",
            component: () => import("../components/login/ConfirmCode"),
          },
        ],
      },

      //confirmation code route
      {
        path: "reset-password",
        component: () => import("../views/login/ResetPassword"),
        children: [
          {
            path: "",
            name: "ResetPassword",
            component: () => import("../components/login/ResetPassword"),
          },
        ],
      },

      //forgot password route
      {
        path: "forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
      },

      //ADMINISTRATOR
      {
        path: "admin",
        meta: { requiresAuth: true },
        component: () => import("../views/mijini/Home"),
        children: [
          {
            path: "",
            name: "Dashboard",
            component: () => import("../views/mijini/Dashboard"),
          },

          {
            path: "profile",
            component: () => import("../views/mijini/Profile"),
            children: [
              {
                path: "",
                name: "ProfileView",
                component: () =>
                  import("../components/mijini/profile/ProfileView"),
              },
              {
                path: "edit/:id",
                name: "ProfileEdit",
                component: () =>
                  import("../components/mijini/profile/ProfileEdit"),
              },
            ],
          },

          // GIVE CHILDREN TO ROUTE THE LISTS
          {
            path: "clients",
            component: () => import("../views/mijini/Owners"),
            children: [
              {
                path: "",
                name: "ViewOwner",
                component: () =>
                  import("../components/mijini/owners/viewOwners"),
              },
              {
                path: "create",
                name: "RegisterBusinessOwner",
                component: () =>
                  import("../components/mijini/owners/RegisterBusinessOwner"),
              },
              {
                path: "profile/:id",
                name: "profileOwner",
                component: () =>
                  import("../components/mijini/owners/profileOwner"),
              },
              {
                path: "edit/:id",
                name: "EditOwner",
                component: () =>
                  import("../components/mijini/owners/editOwner"),
              },
            ],
          },
          {
            path: "stores/:id",
            component: () => import("../views/mijini/Stores"),
            children: [
              {
                path: "",
                name: "viewStores",
                component: () =>
                  import("../components/mijini/stores/ViewStores"),
              },
              {
                path: "add",
                name: "AddStore",
                component: () =>
                  import("../components/mijini/stores/CreateStore"),
              },
              {
                path: "view/:busId",
                name: "StoreDetails",
                component: () =>
                  import("../components/mijini/stores/ViewStoreDetails"),
              },
              {
                path: "edit/:busId",
                name: "StoreEdit",
                component: () =>
                  import("../components/mijini/stores/StoreEdit"),
              },
            ],
          },
          {
            path: "packages",
            component: () => import("../views/mijini/Packages"),
            children: [
              {
                path: "",
                name: "Packages",
                component: () =>
                  import("../components/mijini/packages/viewPackages"),
              },
              {
                path: "update-package/:packageId",
                name: "updatePackages",
                component: () =>
                  import("../components/mijini/packages/updatePackages"),
              },
              {
                path: "add-packages",
                name: "addPackages",
                component: () =>
                  import("../components/mijini/packages/addPackages"),
              },
              {
                path: "licenses",
                name: "Licenses",
                component: () =>
                  import("../components/mijini/packages/viewLicenses"),
              },

              {
                path: "assign/:packageId",
                name: "AssignPackages",
                component: () =>
                  import("../components/mijini/packages/assignPackage"),
              },

              {
                path: "update/:licenseId",
                name: "UpdateLicense",
                component: () =>
                  import("../components/mijini/packages/updateLicense"),
              },
            ],
          },

          {
            path: "reports",
            name: "Reports",
            component: () => import("../views/mijini/Reports"),
          },
          {
            path: "subscription",
            name: "Subscription",
            component: () => import("../views/mijini/Finances"),
          },
          {
            path: "configurations",
            component: () => import("../views/mijini/Configurations"),
            children: [
              {
                path: "",
                name: "Configurations",
                component: () =>
                  import("../components/mijini/configurations/Configurations"),
              },
              {
                path: "configurations/:ownerId",
                name: "ConfigurationsData",
                component: () =>
                  import(
                    "../components/mijini/configurations/ConfigurationsData"
                  ),
              },
            ],
          },
          {
            path: "users",
            component: () => import("../views/mijini/Users"),
            children: [
              {
                path: "",
                name: "View",
                component: () => import("../components/mijini/users/viewUser"),
              },
              {
                path: "create",
                name: "RegisterUser",
                component: () =>
                  import("../components/mijini/users/registerUser"),
              },
            ],
          },

          {
            path: "logs",
            name: "Logs",
            component: () => import("../views/mijini/Logs"),
          },
          {
            path: "suppliers",
            name: "Suppliers",
            component: () => import("../views/mijini/Suppliers"),
          },
        ],
      },

      //owner starts here
      {
        path: "register",
        component: () => import("../views/shop/Register"),
        children: [
          {
            path: "",
            name: "Register",
            component: () => import("../components/shop/register/Register"),
          },
        ],
      },

      {
        path: "send-mail",
        name: "SendMail",
        component: () => import("../views/shop/SendMail"),
      },
      //requires auth starts here.
      {
        path: "businesses",
        name: "SelectBusiness",
        meta: { requiresAuth: true },
        component: () => import("../views/shop/Businesses"),
      },
      {
        path: "data-sync",
        name: "DataSync",
        meta: { requiresAuth: true },
        component: () => import("../views/shop/DataSync"),
      },
      {
        path: "",
        meta: { requiresAuth: true },
        component: () => import("../views/shop/Home"),
        children: [
          {
            path: "",
            name: "DashboardShop",
            component: () => import("../views/shop/Dashboard"),
          },
          {
            path: "human-resource",
            component: () => import("../views/shop/HumanResource"),
            children: [
              {
                path: "",
                name: "HRDashboard",
                component: () =>
                  import("../components/shop/humanResource/dashboardHR"),
              },
              {
                path: "employees",
                name: "ViewEmployees",
                component: () =>
                  import("../components/shop/humanResource/ViewEmployees"),
              },
              {
                path: "create",
                name: "RegisterEmployee",
                component: () =>
                  import("../components/shop/humanResource/RegisterEmployee"),
              },
              {
                path: "view/:id",
                name: "ViewIndividual",
                component: () =>
                  import("../components/shop/humanResource/ViewIndividual"),
              },
              {
                path: "edit/:id",
                name: "EditEmployee",
                component: () =>
                  import("../components/shop/humanResource/EditEmployee"),
              },
              {
                path: "shifts",
                name: "Shifts",
                component: () =>
                  import("../components/shop/humanResource/Shifts"),
              },
              {
                path: "shifts/new-shift",
                name: "CreateShift",
                component: () =>
                  import("../components/shop/humanResource/NewShift"),
              },
              {
                path: "shifts/view-shift/:id",
                name: "ViewShift",
                component: () =>
                  import("../components/shop/humanResource/ViewShiftDetails"),
              },
              {
                path: "shifts/update-shift/:id",
                name: "UpdateShift",
                component: () =>
                  import("../components/shop/humanResource/UpdateShift"),
              },

              {
                path: "shifts/view-personal-shifts/:id",
                name: "ViewPersonalShift",
                component: () =>
                  import("../components/shop/humanResource/PersonalShifts"),
              },

              {
                path: "leaves",
                name: "Leave",
                component: () =>
                  import(
                    "../components/shop/humanResource/ListOfLeaveRequests.vue"
                  ),
              },
              {
                path: "leaves/view-employee-requests/:id",
                name: "ViewIndividualLeaveRequests",
                component: () =>
                  import(
                    "../components/shop/humanResource/IndividualLeaveRequests"
                  ),
              },
              {
                path: "leaves/view-leave-details/:id",
                name: "ViewLeaveDetails",
                component: () =>
                  import("../components/shop/humanResource/ViewLeaveDetails"),
              },
              {
                path: "payroll",
                name: "Payroll",
                component: () =>
                  import("../components/shop/humanResource/Payrolls.vue"),
              },
              {
                path: "monthly-payroll",
                name: "MonthlyPayroll",
                component: () =>
                  import("../components/shop/humanResource/MonthlyPayroll.vue"),
              },
              {
                path: "payroll/view-payroll-details/:id",
                name: "ViewPayrollDetails",
                component: () =>
                  import("../components/shop/humanResource/ViewPaySlip"),
              },
              {
                path: "attendance",
                name: "Attendance",
                component: () =>
                  import("../components/shop/humanResource/Attendance.vue"),
              },
              {
                path: "attendance/view-attendance-details/:id",
                name: "ViewAttendanceDetails",
                component: () =>
                  import(
                    "../components/shop/humanResource/ViewAttendanceDetails"
                  ),
              },
              {
                path: "attendance/new-attendance/:id",
                name: "CreateAttendance",
                component: () =>
                  import("../components/shop/humanResource/NewAttendance"),
              },
              {
                path: "overtimes",
                name: "Overtimes",
                component: () =>
                  import("../components/shop/humanResource/Overtimes.vue"),
              },
              {
                path: "overtimes/view-overtime-details/:id",
                name: "ViewOvertimeDetails",
                component: () =>
                  import(
                    "../components/shop/humanResource/ViewOvertimeDetails"
                  ),
              },
              {
                path: "overtimes/new-overtime/:id",
                name: "CreateOvertime",
                component: () =>
                  import("../components/shop/humanResource/NewOvertime"),
              },

              {
                path: "expense-claims",
                name: "ExpenseClaims",
                component: () =>
                  import("../components/shop/humanResource/ExpenseClaims.vue"),
              },
              {
                path: "expense-claims/view-employee-claims/:id",
                name: "ViewIndividualExpenseClaims",
                component: () =>
                  import(
                    "../components/shop/humanResource/IndividualExpenseClaims"
                  ),
              },
              {
                path: "expense-claims/view-expense-claims/:id",
                name: "ViewExpenseClaims",
                component: () =>
                  import("../components/shop/humanResource/ViewExpenseClaims"),
              },

              {
                path: "expense-claims/edit-expense-claims/:id",
                name: "EditExpenseClaims",
                component: () =>
                  import("../components/shop/humanResource/EditExpenseClaim"),
              },

              {
                path: "expense-claims/new-expense-claim",
                name: "CreateExpenseClaims-1",
                component: () =>
                  import("../components/shop/humanResource/NewExpenseClaim"),
              },
              {
                path: "expense-claims/new-expense-claim/:id",
                name: "CreateExpenseClaims-2",
                component: () =>
                  import("../components/shop/humanResource/NewExpenseClaim"),
              },
            ],
          },

          {
            path: "project-management",
            component: () => import("../views/shop/ProjectManagement"),
            children: [
              {
                path: "",
                name: "ViewProjects",
                component: () =>
                  import("../components/shop/projectManagement/Projects"),
              },
              {
                path: "new-project",
                name: "NewProject",
                component: () =>
                  import("../components/shop/projectManagement/NewProject"),
              },
              {
                path: "project-management/edit-project/:id",
                name: "EditProject",
                component: () =>
                  import("../components/shop/projectManagement/EditProject"),
              },

              {
                path: "project-management/view-project/:id",
                name: "ViewProject",
                component: () =>
                  import("../components/shop/projectManagement/ViewProject"),
              },
            ],
          },

          {
            path: "stores",
            component: () => import("../views/shop/Stores"),
            children: [
              {
                path: "",
                name: "ViewStores",
                component: () => import("../components/shop/stores/ViewStores"),
              },
              {
                path: "create",
                name: "CreateStore",
                component: () =>
                  import("../components/shop/stores/CreateStore"),
              },
              {
                path: "view/:id",
                name: "ViewStoreDetails",
                component: () =>
                  import("../components/shop/stores/ViewStoreDetails"),
              },
              {
                path: "edit/:id",
                name: "EditStore",
                component: () => import("../components/shop/stores/EditStore"),
              },
            ],
          },

          {
            path: "point-of-sale",
            name: "POS",
            component: () => import("../views/shop/Pos"),
          },
          {
            path: "test",
            name: "Test",
            component: () => import("../views/shop/Test"),
          },
          {
            path: "restaurant-point-of-sale",
            name: "RestaurantPOS",
            component: () => import("../views/shop/RestaurantPos"),
          },
          {
            path: "chef",
            name: "Chef",
            component: () => import("../components/shop/chef/Chef"),
          },
          {
            path: "all-orders",
            name: "allOrders",
            component: () => import("../components/shop/orders/allOrders.vue"),
          },
          {
            path: "orders",
            name: "Orders",
            component: () => import("../components/shop/orders/Orders.vue"),
          },
          {
            path: "cancelled-orders",
            name: "cancelledOrders",
            component: () =>
              import("../components/shop/orders/cancelledOrders.vue"),
          },

          {
            path: "accounts",
            component: () => import("../views/shop/Accounts"),
            children: [
              {
                path: "",
                name: "DashboardAccount",
                component: () =>
                  import("../components/shop/account/dashboardAccount"),
              },
              {
                path: "chart-account",
                name: "ChartAccount",
                component: () =>
                  import("../components/shop/account/chartAccount"),
              },
              {
                path: "new-account",
                name: "NewAccount",
                component: () =>
                  import("../components/shop/account/newAccount"),
              },
              {
                path: "view-account/:id",
                name: "ViewAccount",
                component: () =>
                  import("../components/shop/account/viewAccount"),
              },

              {
                path: "update-account/:id",
                name: "UpdateAccount",
                component: () =>
                  import("../components/shop/account/updateAccount"),
              },
              {
                path: "sales-invoice",
                name: "SalesInvoice",
                component: () =>
                  import("../components/shop/account/salesInvoice"),
              },
              {
                path: "journal-entry",
                name: "JournalEntry",
                component: () =>
                  import("../components/shop/account/journalEntry"),
              },
              {
                path: "payment-entry",
                name: "PaymentEntry",
                component: () =>
                  import("../components/shop/account/paymentEntry"),
              },

              {
                path: "payables",
                name: "Payables",
                component: () => import("../components/shop/account/payables"),
              },
              {
                path: "purchase-invoice",
                name: "PurchaseInvoice",
                component: () =>
                  import("../components/shop/account/purchaseInvoice"),
              },
              {
                path: "account-receivables",
                name: "AccountReceivables",
                component: () =>
                  import("../components/shop/account/accountReceivables"),
              },
              {
                path: "reports",
                name: "ReportAccount",
                component: () => import("../components/shop/account/reports"),
              },
            ],
          },

          {
            path: "sales",
            component: () => import("../views/shop/Sales"),
            children: [
              {
                path: "",
                name: "ViewSales",
                component: () => import("../components/shop/sales/ViewSales"),
              },
              {
                path: "view/:id",
                name: "ViewSaleDetails",
                component: () =>
                  import("../components/shop/sales/ViewSaleDetails"),
              },
              {
                path: "receipts",
                name: "ViewReceipts",
                component: () =>
                  import("../components/shop/sales/ViewReceipts"),
              },
              {
                path: "receipt/:id",
                name: "ViewReceiptDetails",
                component: () =>
                  import("../components/shop/sales/ViewReceiptDetails"),
              },
            ],
          },

          {
            path: "stock",
            component: () => import("../views/shop/Stock"),
            children: [
              {
                path: "",
                name: "StockMenu",
                component: () => import("../components/shop/stock/stockMenu"),
              },
              {
                path: "stock-items",
                name: "StockItems",
                component: () =>
                  import("../components/shop/stock/products/StockItems"),
              },
              {
                path: "stock-items/:id",
                name: "ViewProductDetails",
                component: () =>
                  import(
                    "../components/shop/stock/products/ViewProductDetails"
                  ),
              },
              {
                path: "stock-item/enter-product",
                name: "EnterProduct",
                component: () =>
                  import("../components/shop/stock/products/EnterProduct"),
              },
              {
                path: "stock-items/edit/:id",
                name: "EditProduct",
                component: () =>
                  import("../components/shop/stock/products/EditProduct"),
              },

              {
                path: "stock-management",
                name: "ViewStock",
                component: () =>
                  import("../components/shop/stock/stockManagement/ViewStock"),
              },
              {
                path: "stock-management/update-stock/:id",
                name: "UpdateStock",
                component: () =>
                  import(
                    "../components/shop/stock/stockManagement/UpdateStock"
                  ),
              },

              {
                path: "purchase-order",
                name: "ViewPurchaseOrders",
                component: () =>
                  import("../components/shop/stock/po/ViewPurchaseOrders"),
              },
              {
                path: "received-purchase-order",
                name: "ReceivedPurchaseOrders",
                component: () =>
                  import("../components/shop/stock/po/ReceivedPurchaseOrders"),
              },

              {
                path: "create-purchase-order",
                name: "CreatePurchaseOrder",
                component: () =>
                  import("../components/shop/stock/po/CreatePurchaseOrder"),
              },
              {
                path: "view-purchase-order/:id",
                name: "PurchaseOrderDetails",
                component: () =>
                  import("../components/shop/stock/po/PurchaseOrderDetails"),
              },
            ],
          },

          {
            path: "services",
            component: () => import("../views/shop/Services"),
            children: [
              {
                path: "",
                name: "ViewServices",
                component: () =>
                  import("../components/shop/services/ViewServices"),
              },
              {
                path: "create-service",
                name: "CreateService",
                component: () =>
                  import("../components/shop/services/CreateService"),
              },
              {
                path: "view/:id",
                name: "ViewServiceDetails",
                component: () =>
                  import("../components/shop/services/ViewServiceDetails"),
              },
              {
                path: "edit/:id",
                name: "EditService",
                component: () =>
                  import("../components/shop/services/EditService"),
              },
            ],
          },

          {
            path: "suppliers",
            component: () => import("../views/shop/Suppliers"),
            children: [
              {
                path: "",
                name: "ViewSuppliers",
                component: () =>
                  import("../components/shop/suppliers/ViewSuppliers"),
              },
              {
                path: "register-supplier",
                name: "RegisterSupplier",
                component: () =>
                  import("../components/shop/suppliers/RegisterSupplier"),
              },
              {
                path: ":id",
                name: "ViewSupplierProfile",
                component: () =>
                  import("../components/shop/suppliers/ViewSupplierProfile"),
              },
              {
                path: "edit-supplier/:id",
                name: "EditSupplier",
                component: () =>
                  import("../components/shop/suppliers/EditSupplier"),
              },
            ],
          },

          {
            path: "reports",
            component: () => import("../views/shop/Reports"),
            children: [
              // sales
              {
                path: "",
                name: "SalesReports",
                component: () =>
                  import("../components/shop/reports/sales/SalesReports"),
              },
              {
                path: "monthly-sales",
                name: "MonthlySalesReport",
                component: () =>
                  import("../components/shop/reports/sales/MonthlySalesReport"),
              },
              {
                path: "quarterly-sales",
                name: "QuarterlySalesReport",
                component: () =>
                  import(
                    "../components/shop/reports/sales/QuarterlySalesReport"
                  ),
              },
              {
                path: "annual-sales",
                name: "AnnualSalesReport",
                component: () =>
                  import("../components/shop/reports/sales/AnnualSalesReport"),
              },

              // stock/inventory
              {
                path: "stock-balance",
                name: "StockBalance",
                component: () =>
                  import("../components/shop/reports/stock/StockBalance"),
              },

              // p & l
              {
                path: "income-statement",
                name: "IncomeStatement",
                component: () =>
                  import(
                    "../components/shop/reports/incomeStatement/IncomeStatement"
                  ),
              },
            ],
          },

          {
            path: "settings",
            component: () => import("../views/shop/Settings"),
            children: [
              {
                path: "system",
                name: "SystemGeneral",
                component: () => import("../components/shop/settings/System"),
              },
              {
                path: "currency",
                name: "Currency",
                component: () =>
                  import("../components/shop/settings/currency/ViewCurrency"),
              },
              {
                path: "timezone",
                name: "TimeZone",
                component: () => import("../components/shop/settings/TimeZone"),
              },
              {
                path: "payment-mode",
                name: "PaymentMode",
                component: () =>
                  import("../components/shop/settings/paymentMode"),
              },

              {
                path: "license",
                name: "License",
                component: () =>
                  import("../components/shop/settings/license/License"),
              },
              {
                path: "updateLicense/:licenseId",
                name: "updateLicense",
                component: () =>
                  import("../components/shop/settings/license/updateLicense"),
              },
              {
                path: "accounting",
                name: "Accounting",
                component: () =>
                  import("../components/shop/settings/accounting/Accounting"),
              },

              {
                path: "payroll-settings",
                name: "payrollSettings",
                component: () =>
                  import("../components/shop/settings/payroll/Payroll"),
              },
              {
                path: "Help",
                name: "Help",
                component: () => import("../components/shop/settings/Help"),
              },
            ],
          },

          {
            path: "profile",
            component: () => import("../views/shop/Profile"),
            children: [
              {
                path: "",
                name: "ViewProfile",
                component: () =>
                  import("../components/shop/profile/ViewProfile"),
              },
              {
                path: "edit/:id",
                name: "EditProfile",
                component: () =>
                  import("../components/shop/profile/EditProfile"),
              },
            ],
          },
        ],
      },
    ],
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   let loginStatus = store.getters.isAuthenticated;

//   let requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
//   let user = store.getters.isAuthenticated;

//   if (requiresAuth && !user && !loginStatus) next(`/${i18n.locale}/login`);
//   else next();
// });

router.beforeEach((to, from, next) => {
  let loginStatus = store.getters.isAuthenticated;

  let requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth) {
    if (!loginStatus) next(`/${i18n.locale}/login`);
    else next();
  } else next();
});

export default router;
