<template>
  <v-app>
    <router-view />
    <v-dialog
      v-model="installationDialog"
      v-if="deferredPrompt"
      persistent
      max-width="290"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Open Dialog
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5"> {{ $t("install app") }} </v-card-title>
        <v-card-text
          >{{
            $t(
              "get our free app. It won't take up space on your phone and also works offline"
            )
          }}!</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :color="$store.state.secondaryColor" text @click="dismiss"
            >{{ $t("dismiss") }}
          </v-btn>
          <v-btn :color="$store.state.primaryColor" text @click="install"
            >{{ $t("install") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      top
      centered
      color="warning lighten-5 orange--text text--darken-2"
      :timeout="4000"
    >
      <span>{{ $t("are you still using the system") }}</span>
      <v-btn icon color="warning" @click="snackbar = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import countries from "./data/worldcountries.json";
import db from "./plugins/fb";
import jwtdecode from "jwt-decode";

export default {
  name: "App",
  data: function () {
    return {
      apilink: "",
      countries: countries,
      events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
      installationDialog: true,
      warningTimer: null,
      logoutTimer: null,
      snackbar: false,

      longitude: "",
      latitude: "",
      apikey: "",
      deferredPrompt: null,
    };
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
      this.installationDialog = false;
    });
  },
  mounted() {
    this.checkActivity();
    this.worldCountries();
  },

  methods: {
    checkActivity() {
      this.events.forEach((event) => {
        window.addEventListener(event, this.resetTimer);
      });

      this.setTimers();
    },

    setTimers() {
      if (localStorage.getItem("vuex") != null) {
        this.warningTimer = setTimeout(this.warningMessage, 14 * 60 * 1000); //14 minutes  14*60*1000
        this.logoutTimer = setTimeout(this.logout, 15 * 60 * 1000); // 15 minutes 15*60*1000
        this.snackbar = false;
      }
    },

    warningMessage() {
      this.snackbar = true;
    },

    resetTimer() {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);
      this.setTimers();
    },
    async dismiss() {
      this.deferredPrompt = null;
      this.installationDialog = false;
    },
    async install() {
      this.deferredPrompt.prompt();
      this.installationDialog = false;
    },
    logout() {
      let cleardata = {
        status: false,
        userId: "",
      };

      var data = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      ); //Fetching current user details
      var currentLog = this.$store.state.logger; // Fetching Existing Logs  on store

      // Updating Log out Date and Time
      db.collection("logs").doc(currentLog).update({
        dateTimeOut: new Date(),
      });

      // Update user session

      db.collection("users").doc(data.accessLevel.id).update({
        status: false,
      });

      //Clear Vuex Store data

      let clearLogs = "";
      let cleartoken = "";
      this.$store.commit("SET_LOGS", clearLogs);
      this.$store.commit("SET_SESSION", cleardata);
      this.$store.commit("SET_TOKEN", cleartoken);

      //Redirect to Login page and Clear Local storage data cache and

      localStorage.clear();

      var lang = "en";

      this.$i18n.locale = lang;

      //store translation language to store
      localStorage.setItem("lang", lang);
      this.$router.push({ name: "Login" });
    },
    worldCountries() {
      //store countries to the store
      this.$store.commit("SET_COUNTRY_CODES", countries);
    },
  },
};
</script>

<style>
:root {
  --themeColor: #263238;
  --primaryColor: #038cbe;
  /* --secondaryColor  : #b94118; */
  --secondaryColor: #ef6c00;
}

.bkg {
  background-image: url(./assets/background.jpg);
  background-size: cover;
  /* background-repeat: repeat; */
}

.sideScreen {
  background-image: url(./assets/sideScreen.png);
  background-size: cover;
  /* background-repeat: repeat; */
}

.overlay-main {
  position: absolute; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000075; /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  /* cursor: pointer; Add a pointer on hover */
}

.dashboard-admin {
  /* background-image: url(./assets/dashboard.jpg); */
  background-size: cover;
}

.dashboard-shop {
  background-image: url(./assets/background.jpg);
  background-size: cover;
}
.gradients {
  background-image: linear-gradient(to bottom right, #0579a3, #005270);

  /* background-image: linear-gradient(to bottom right, #072033,#103c5e); */
}
.navGradients {
  background-image: linear-gradient(to top right, #243140, #081522);
}

.mainFont {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.card-color {
  background-color: var(--themeColor);
  border-radius: 2px;
  border-left: 8px solid #ef6c00;
  color: #ffffff;
  text-align: center;
}

.picked {
  background-color: #ef6c0060;
  border-left: 8px solid #ef6c00;
}

.input-style {
  padding: 5px;
  background-color: #fff;
  border: 1px solid #000000;
  border-radius: 5px;
}

.input-style:focus {
  box-shadow: 0 0 3px #ef6c00;
  outline-offset: 0px !important;
  outline: none !important;
}

.input-dashed {
  padding: 5px;
  border-bottom: 1px dotted black;
}
.input-dashed:focus {
  box-shadow: -2px 2px 5px grey;
  outline-offset: 0px !important;
  outline: none !important;
}

.dashed {
  padding: 2px;
  margin-left: 2px;
  border-bottom: 1px dotted black;
}

.tiny {
  font-size: 60% !important;
}
.smaller {
  font-size: 90% !important;
}
</style>
