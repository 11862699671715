import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import flagIcon from "vue-flag-icon";
import i18n from "./plugins/i18n";

import Flutterwave from "flutterwave-vue-v3";
Vue.use(Flutterwave, {
  publicKey: "FLWPUBK_TEST-8820754c9d7da95983ce43e1c2f7466e-X",
});

import VueCarousel from "vue-carousel";
import VueCountdown from "vue-awesome-countdown";
import VueGoogleCharts from "vue-google-charts";

import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

import VueBarcode from "vue-barcode";

import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import './registerServiceWorker'

Vue.config.productionTip = false;

Vue.use(VueCarousel);
Vue.use(VueCountdown);
Vue.use(VueGoogleCharts);
Vue.use(flagIcon);
Vue.use(VueTelInput);
Vue.component("barcode", VueBarcode);

router.beforeEach((to, from, next) => {
  // let language = to.params.lang;
  //get translation from local storage
  let language = localStorage.getItem("lang" || "en");
  if (language == "" || i18n.availableLocales.includes(language) == false) {
    language = "en";
  }
  i18n.locale = language;
  next();
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
