import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

// import db from '@/plugins/fb'

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    todaysDate: "",
    currency: "",
    employerId: "",
    currentUser: "",
    userData: "",
    allowedBranches: "",
    countries: "",
    isLoggedIn: false,
    gradientColor: "var(--gradientColor)",
    appColor: "var(--themeColor)",
    primaryColor: "var(--primaryColor)",
    secondaryColor: "var(--secondaryColor)",
    token: "",
    accessKey: "MijiniTech",
    logger: "",
    windowTitle: "UPSELL",
    cart: [],
    businesses: [],
    earnings: [],
    deductions: [],
    shifts: [],
    products: [],
    productsPos: [],
    orders: [],
    documents: [],
    accounts: [],
    projects: [],
    businessId: [],
    selectedBusiness: "",
    selectedSupplier: "",
    selectedBusinessTemp: "",
    selectedBusinessCart: "",

    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],

    years: [
      "2001",
      "2002",
      "2003",
      "2004",
      "2005",
      "2006",
      "2007",
      "2008",
      "2009",
      "2010",
      "2011",
      "2012",
      "2013",
      "2014",
      "2015",
      "2016",
      "2017",
      "2018",
      "2019",
      "2020",
      "2021",
      "2022",
      "2023",
      "2024",
    ],
    quarters: [
      "January - March",
      "April - June",
      "July - September",
      "October - December",
    ],
    businessTypes: [
      "Clothing store",
      "Restaurant",
      "Bars",
      "Night Club",
      "Furniture shop",
    ],
  },

  mutations: {
    SET_CURRENCY(state, payload) {
      state.currency = payload;
    },
    SET_EMPLOYERID(state, payload) {
      state.employerId = payload;
    },

    SET_COUNTRY_CODES(state, payload) {
      state.countries = payload;
    },

    SET_SESSION(state, payload) {
      state.isLoggedIn = payload.status;
      state.currentUser = payload.userId;
    },

    SET_USERDATA(state, payload) {
      state.userData = payload;
    },

    SET_PRODUCTS(state, payload) {
      state.products = payload;
    },

    SET_PRODUCTS_POS(state, payload) {
      state.productsPos = payload;
    },

    SET_ORDERS(state, payload) {
      state.orders = payload;
    },

    SET_ACCOUNTS(state, payload) {
      state.accounts = payload;
    },

    SET_PROJECTS(state, payload) {
      state.projects = payload;
    },

    SET_TOKEN(state, payload) {
      state.token = payload;
    },

    SET_NUMBER_OF_ALLOWED_STORES(state, payload) {
      state.allowedBranches = payload;
    },

    SET_WINDOWTITLE(state, payload) {
      state.windowTitle = payload;
    },
    SET_EARNINGS(state, payload) {
      state.earnings = payload;
    },
    SET_DEDUCTIONS(state, payload) {
      state.deductions = payload;
    },

    SET_DOCUMENTS(state, payload) {
      state.documents = payload;
    },

    SET_LOGS(state, payload) {
      state.logger = payload;
    },

    SET_BUSINESS_ID(state, payload) {
      state.businessId = payload;
    },

    SET_BUSINESS(state, payload) {
      state.businesses = payload;
    },
    SET_SHIFTS(state, payload) {
      state.shifts = payload;
    },

    SET_TODAY_DATE(state, payload) {
      state.todaysDate = payload;
    },

    SET_CART(state, payload) {
      state.cart = payload;
    },

    SET_SELECTED_BUSINESS(state, payload) {
      state.selectedBusiness = payload;
    },
    SET_SELECTED_SUPPLIER(state, payload) {
      state.selectedSupplier = payload;
    },
    SET_SELECTED_BUSINESS_TEMP(state, payload) {
      state.selectedBusinessTemp = payload;
    },
    SET_SELECTED_BUSINESS_CART(state, payload) {
      state.selectedBusinessCart = payload;
    },
  },

  getters: {
    isAuthenticated(state) {
      return state.currentUser !== "" && state.isLoggedIn == true;
    },

    getCurrentBusiness(state) {
      return state.selectedBusiness;
    },
    getSelectedSupplier(state) {
      return state.selectedSupplier;
    },
    getCurrentBusinessTemp(state) {
      return state.selectedBusinessTemp;
    },

    getCurrentBusinessCart(state) {
      return state.selectedBusinessCart;
    },

    getBusinesses(state) {
      return state.businesses;
    },
    getShifts(state) {
      return state.shifts;
    },
    getUserData(state) {
      return state.userData;
    },

    getProducts(state) {
      return state.products;
    },
    getProductsPos(state) {
      return state.productsPos;
    },

    getOrders(state) {
      return state.orders;
    },

    getAccounts(state) {
      return state.accounts;
    },

    getProjects(state) {
      return state.projects;
    },

    getDocuments(state) {
      return state.documents;
    },

    getCart(state) {
      return state.cart;
    },

    getallowedBranches(state) {
      return state.allowedBranches;
    },

    getMonths(state) {
      return state.months;
    },

    getYears(state) {
      return state.years;
    },
    getEarnings(state) {
      return state.earnings;
    },
    getDeductions(state) {
      return state.deductions;
    },

    getQuarters(state) {
      return state.quarters;
    },

    getBusinessTypes(state) {
      return state.businessTypes;
    },

    getBusinessId(state) {
      return state.businesses.map((business) => business.id);
    },

    getCountries(state) {
      return state.countries;
    },
  },

  actions: {},
});

export default store;
