<template>
  <v-footer app class="justify-space-between footer">
    <span class="caption"
      >{{ $t("powered by") }}
      <a class="" target="_blank" href="https://www.mijinitech.com"
        >MijiniTech <span class="caption"> &copy; {{ currentYear }}</span></a
      ></span
    >
    <v-spacer></v-spacer>
    <a href="help"
      ><span class="caption">{{ $t("help") }}</span></a
    >
    &nbsp;
    <a href="help"
      ><span class="caption">{{ $t("legal terms") }}</span></a
    >
  </v-footer>
</template>

<script>
import { format } from "date-fns";
export default {
  name: "Permis",
  data: function () {
    return {
      currentYear: format(new Date(), "yyyy"),
      drawer: false,
    };
  },
};
</script>
<style scoped>
.footer {
  bottom: 0;
}
</style>
