//import nodemailer from "nodemailer";
function sendEmail(email) {
  console.log(email);
  var nodemailer = require("nodemailer");

  var transporter = nodemailer.createTransport({
    service: "gmail",
    secure: false,
    port: 465,
    auth: {
      user: "kmarcelus92@gmail.com",
      pass: "RRUdg6UrAX325p",
    },
  });

  var mailOptions = {
    from: '"Upsell"<kmarcelus92@gmail.com>',
    to: email,
    subject: "Welcome to Upsell",
    text: `Hello, Email sent!!
        `,
  };

  process.env["NODE_TLS_REJECT_UNAUTHORIZED"] = 0;
  transporter.sendMail(mailOptions, function (error, info) {
    if (error) {
      console.log("nodemailer errors");
      console.log(error);
    } else {
      console.log("Email sent to Joel", info);
    }
  });
}
export { sendEmail };
