<template>
  <v-app>
    <!-- TOP BAR -->
    <v-app-bar app dense class="elevation-1 white hidden-md-and-up">
      <v-card @click="reload()" class="transparent pa-2" flat>
        <router-link to="login" style="cursor: pointer">
          <v-img
            contain
            src="@/assets/Tlogo.png"
            width="120"
            height="auto"
          ></v-img>
        </router-link>
      </v-card>
    </v-app-bar>
    <v-layout row wrap class="mt-3 grey lighten-3">
      <v-flex xs12 sm12 md8 lg8 class="px-16 mb-6" style="margin-top: 5%">
        <ForgotPassword></ForgotPassword>
      </v-flex>
      <v-flex xs12 sm4 md4 lg4 class="hidden-sm-and-down">
        <v-img
          contain
          src="@/assets/landing.jpg"
          width="100%"
          class="img full-height"
        />
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import ForgotPassword from "@/components/login/ForgotPassword";

export default {
  components: {
    ForgotPassword,
  },

  data() {
    return {
      title: "Forgot Password",
      isSelected: true,
    };
  },

  mounted() {
    localStorage.removeItem("vuex");
    window.addEventListener("online", this.checkConnection);
    window.addEventListener("offline", this.checkConnection);
  },

  methods: {
    checkConnection() {
      this.snackOnline = navigator.onLine ? false : true;
    },

    toSwitch(menu) {
      switch (menu) {
        case "login":
          this.isSelected = true;
          this.$router.push("/login");
          break;
        case "register":
          this.isSelected = false;
          this.$router.push("/login/register");
          break;
      }
    },
  },
};
</script>
<style scoped>
.full-height {
  height: 100%;
  background-image: linear-gradient(to bottom, #f0af7a, #62d2fa);
}
/* .img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30%;
  margin-bottom: 30%;
} */
</style>
