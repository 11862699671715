import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

var firebaseConfig = {
  apiKey: "AIzaSyBTNscFE67jiKjJQKBZX5O_t9-vl96SW0M",
  authDomain: "upsell-51f60.firebaseapp.com",
  databaseURL: "https://upsell-51f60.firebaseio.com",
  projectId: "upsell-51f60",
  storageBucket: "upsell-51f60.appspot.com",
  messagingSenderId: "550883817313",
  appId: "1:550883817313:web:556553093c29ad4632b720",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

db.enablePersistence().catch((err) => {
  if (err.code == "failed-precondition") {
    console.log("persistence failed");
  } else if (err.code == "unimplemented") {
    console.log("persistence is not available");
  }
});

export default db;
